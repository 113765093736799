<div class="m-3">
  <div class="headerBlock">
    <div *ngIf="!baseHeaderVisible" class="container-fluid row fixed-top"
      style="z-index:10000; border: 1px solid lightgray; background-color: rgba(255, 255, 255, 0.8);">
      <div class="col-5">
        <div class="row">
          <div class="col" style="font-variant-caps: small-caps;">
            [{{currentRole}}], {{itemOwner? 'Item Owner': 'Non-Owner'}}
          </div>
        </div>
        <div class="row">
          <div class="col" style="display:inline-block">
            <h3 *ngIf="createMode === 'bb'">{{readOnly ? ' View ' : currentAction}} Building Block {{loadedItem.isTemplate
              ? ' Template ': ''}} {{loadedItem.isDraft ? ' Draft ': ''}} <span
                *ngIf="loadedItem && (currentAction === 'Edit')"> - {{loadedItem.buildingBlockNumber}}
                {{loadedItem.isArchived ? ' (Archived) ': ''}} </span></h3>
            <h3 *ngIf="createMode === 'recipe'"> {{readOnly ? ' View ' : currentAction}} Recipe {{loadedItem.isTemplate ?
              ' Template ': ''}} {{loadedItem.isDraft ? ' Draft ': ''}} <span
                *ngIf="loadedItem && (currentAction === 'Edit')"> - {{loadedItem.recipeNumber}} {{loadedItem.isArchived ?
                ' (Archived) ': ''}}</span></h3>
          </div>

        </div>
      </div>
      <div class="col-2">
        <!-- <div class="k-loading-panel" *ngIf="loading['rmItem']">
          <div class="k-loading-panel-mask"></div>
          <div class="k-loading-panel-wrapper">
            <div class="k-loading-panel-text">Loading...</div>
          </div>
        </div> -->
      </div>
      <div class="col-5">
        <div class="row">
          <div class="col">
            <div class="btn-group" style="float:right">
              <kendo-toolbar>
                <!-- <kendo-toolbar-button text="Edit"
                  *ngIf="viewOnly && loadedItem && loadedItem.id"
                  [routerLink]="['/', createMode, 'edit', loadedItem.id ]"
                  [queryParams]="{createdByWwid: loadedItem.createdBy.wwid}">
                </kendo-toolbar-button> -->

                  <!-- WH keep -->
                  <!-- <kendo-toolbar-button text="GET IDSID"
                    (click)="getUserIdsid()">
                  </kendo-toolbar-button> -->
                  <!-- WH keep -->

                <kendo-toolbar-button text="Save as Draft"
                  *ngIf="currentAction !== 'Edit' && !readOnly"
                  [disabled]="disableSaveButtons" (click)="create(true)">
                </kendo-toolbar-button>

                <kendo-toolbar-button text="Save as Template"
                  *ngIf="currentAction !== 'Edit' && !readOnly && loadedItem.isTemplate"
                  [disabled]="!submitRequestForm.valid" (click)="create(false)">
                </kendo-toolbar-button>

                <kendo-toolbar-button text="Save Draft"
                  *ngIf="loadedItem.isDraft && (currentAction === 'Edit') && !readOnly"
                  (click)="update(true)" [disabled]="disableSaveButtons">
                </kendo-toolbar-button>
                <kendo-toolbar-button text="Save Template"
                  *ngIf="loadedItem.isTemplate && (currentAction === 'Edit') && !readOnly"
                  (click)="update(false)" [disabled]="disableSaveButtons">
                </kendo-toolbar-button>
                <kendo-toolbar-button text="Save"
                  *ngIf="!loadedItem.isDraft && !loadedItem.isTemplate && loadedItem && (currentAction === 'Edit') && !readOnly"
                  (click)="update(false)" [disabled]="!submitRequestForm.valid || disableSaveButtons">
                </kendo-toolbar-button>
                      <!-- Button Panel that stay on TOP RIGHT when page scrolled down (** Note: There are two sets of button panel **)-->
                <kendo-toolbar-button text="Create Production {{createMode === 'bb' ? 'Building Block': 'Recipe'}}"
                  *ngIf="loadedItem.isTemplate"
                  (click)="loadedItem.isTemplate = false; this.currentAction = 'Create'; create(!submitRequestForm.valid)"
                  [disabled]="!submitRequestForm.valid"></kendo-toolbar-button>

                <kendo-toolbar-button text="Create New {{createMode === 'bb' ? 'Building Block': 'Recipe'}}"
                  *ngIf="(currentAction !== 'Edit') && !readOnly && !loadedItem.isTemplate"
                  (click)="create(false)"
                  [disabled]="!submitRequestForm.valid || disableSaveButtons">
                </kendo-toolbar-button>

                <kendo-toolbar-button text="Promote to Production {{createMode === 'bb' ? 'Building Block': 'Recipe'}}"
                  *ngIf="(loadedItem.isDraft) && !readOnly && !loadedItem.isTemplate"
                  (click)="update(false)"
                  [disabled]="!submitRequestForm.valid || disableSaveButtons">
                </kendo-toolbar-button>

                <kendo-toolbar-spacer></kendo-toolbar-spacer>
                <kendo-toolbar-button icon="refresh" iconClass="k-icon k-i-refresh" title="Refresh"
                  (click)="reloadPage()">
                </kendo-toolbar-button>
                <kendo-toolbar-button icon="copy" title="Copy" *ngIf="currentAction === 'Edit'" (click)="linkToCopy()">
                </kendo-toolbar-button>
                <kendo-toolbar-button icon="strip-all-formatting" iconClass="k-icon k-i-strip-all-formatting"
                  (click)="buttonClearCache()" text="Clear Local Cache"></kendo-toolbar-button>
              </kendo-toolbar>
              <div style="display: flex; justify-content: center; align-items: center;"
                *ngIf="itemOwner && (currentAction === 'Edit')">
                <button kendoButton themeColor="warning" size="medium" rounded="full" class="m-1"
                  [disabled]="loading['rmItem']" title="Archive {{createMode === 'bb' ? 'Building Block': 'Recipe'}}"
                  *ngIf="!loadedItem.isArchived" (click)="delete()">
                  <i class="intelicon-arrow-forward-right"></i><i class="intelicon-archive-solid"></i>
                </button>
                <button kendoButton themeColor="warning" size="medium" rounded="full" class="m-1"
                  [disabled]="loading['rmItem']" title="Unarchive {{createMode === 'bb' ? 'Building Block': 'Recipe'}}"
                  *ngIf="loadedItem.isArchived && itemOwner"
                  (click)="loadedItem.isArchived = false; update(loadedItem['isDraft'])">
                  <i class="intelicon-arrow-back-left"></i><i class="intelicon-archive-solid"></i>
                </button>
              </div>
            </div>

          </div>
        </div>
        <div class="row" *ngIf="createMode === 'bb' && loadedItem && (currentAction === 'Edit') && this.readOnly === false">
        <!-- <div class="row" *ngIf="createMode === 'bb' && loadedItem && (currentAction === 'Edit')"> -->
          <div class="col">
            <div class="btn-group" style="float:right">
              <input type="checkbox" #notification [(ngModel)]="updateRecipes" kendoCheckBox />
              <kendo-label class="k-checkbox-label" [for]="notification"
                text="Update all associated recipes that *you* own.">
              </kendo-label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="baseHeaderVisible" class="row bg-light ">
      <div class="col-5">
        <div class="row">
          <div class="col" style="font-variant-caps: small-caps;">
            [{{currentRole}}], {{itemOwner? 'Item Owner': 'Non-Owner'}}
          </div>
        </div>
        <div class="row">
          <div class="col" style="display:inline-block">
            <h3 *ngIf="createMode === 'bb'">{{readOnly ? ' View ' : currentAction}} Building Block {{loadedItem.isTemplate
              ? ' Template ': ''}} {{loadedItem.isDraft ? ' Draft ': ''}} <span
                *ngIf="loadedItem && (currentAction === 'Edit')"> - {{loadedItem.buildingBlockNumber}}
                {{loadedItem.isArchived ? ' (Archived) ': ''}} </span></h3>
            <h3 *ngIf="createMode === 'recipe'"> {{readOnly ? ' View ' : currentAction}} Recipe {{loadedItem.isTemplate ?
              ' Template ': ''}} {{loadedItem.isDraft ? ' Draft ': ''}} <span
                *ngIf="loadedItem && (currentAction === 'Edit')"> - {{loadedItem.recipeNumber}} {{loadedItem.isArchived ?
                ' (Archived) ': ''}}</span></h3>
          </div>
        </div>
      </div>
      <div class="col-2">
        <!-- <div class="k-loading-panel" *ngIf="loading['rmItem']">
          <div class="k-loading-panel-mask"></div>
          <div class="k-loading-panel-wrapper">
            <div class="k-loading-panel-text">Loading...</div>
          </div>
        </div> -->
      </div>
      <div class="col-5">
        <div class="row">
          <div class="col">
            <div class="btn-group" style="float:right">

              <!-- WH - allowUpdateBB -->
              <div class="col" *ngIf="createMode === 'recipe'">
                <div class="btn-group" style="float:right; margin-right:10px">
                  <input type="checkbox" style="margin-top: 5px"
                  [(ngModel)]="allowUpdateBB"
                  (ngModelChange)="onAllowUpdateBBChange($event)"
                   kendoCheckBox />
                  <kendo-label class="k-checkbox-label" [for]="allowUpdateBB"
                    text="Allow update BB when changes made">
                  </kendo-label>
                </div>
              </div>

              <kendo-toolbar>
                <!-- <kendo-toolbar-button text="Edit"
                  *ngIf="viewOnly && loadedItem && loadedItem.id"
                  [routerLink]="['/', createMode, 'edit', loadedItem.id ]"
                  [queryParams]="{createdByWwid: loadedItem.createdBy.wwid}">
                </kendo-toolbar-button> -->

                <!-- WH keep -->
                <!-- <kendo-toolbar-button text="GET IDSID"
                  (click)="getUserIdsid()">
                </kendo-toolbar-button> -->
                <!-- WH keep -->

                <kendo-toolbar-button text="Save as Draft"
                  *ngIf="currentAction !== 'Edit' && !readOnly"
                  [disabled]="disableSaveButtons" (click)="create(true)">
                </kendo-toolbar-button>

                <kendo-toolbar-button text="Save as Template"
                  *ngIf="currentAction !== 'Edit' && !readOnly && loadedItem.isTemplate"
                  [disabled]="!submitRequestForm.valid" (click)="create(false)">
                </kendo-toolbar-button>

                <kendo-toolbar-button text="Save Draft"
                  *ngIf="loadedItem.isDraft && (currentAction === 'Edit') && !readOnly"
                  (click)="update(true)" [disabled]="disableSaveButtons">
                </kendo-toolbar-button>
                <kendo-toolbar-button text="Save Template"
                  *ngIf="loadedItem.isTemplate && (currentAction === 'Edit') && !readOnly"
                  (click)="update(false)" [disabled]="disableSaveButtons">
                </kendo-toolbar-button>
                <kendo-toolbar-button text="Save"
                  *ngIf="!loadedItem.isDraft && !loadedItem.isTemplate && loadedItem && (currentAction === 'Edit') && !readOnly"
                  (click)="update(false)" [disabled]="!submitRequestForm.valid || disableSaveButtons">
                </kendo-toolbar-button>

                <kendo-toolbar-button text="Create Production {{createMode === 'bb' ? 'Building Block': 'Recipe'}}"
                  *ngIf="loadedItem.isTemplate"
                  (click)="loadedItem.isTemplate = false; this.currentAction = 'Create'; create(!submitRequestForm.valid)"
                  [disabled]="!submitRequestForm.valid"></kendo-toolbar-button>

                <kendo-toolbar-button text="Create New {{createMode === 'bb' ? 'Building Block': 'Recipe'}}"
                  *ngIf="(currentAction !== 'Edit') && !readOnly && !loadedItem.isTemplate"
                  (click)="create(false)"
                  [disabled]="!submitRequestForm.valid || disableSaveButtons">
                </kendo-toolbar-button>

                <kendo-toolbar-button text="Save Production {{createMode === 'bb' ? 'Building Block': 'Recipe'}}"
                  *ngIf="(loadedItem.isDraft) && !readOnly && !loadedItem.isTemplate"
                  (click)="update(false)"
                  [disabled]="!submitRequestForm.valid || disableSaveButtons">
                </kendo-toolbar-button>

                <kendo-toolbar-spacer></kendo-toolbar-spacer>
                <kendo-toolbar-button icon="refresh" iconClass="k-icon k-i-refresh" title="Refresh"
                  (click)="reloadPage()">
                </kendo-toolbar-button>
                <kendo-toolbar-button icon="copy" title="Copy" *ngIf="currentAction === 'Edit'" (click)="linkToCopy()">
                </kendo-toolbar-button>
                <kendo-toolbar-button icon="strip-all-formatting" iconClass="k-icon k-i-strip-all-formatting"
                  (click)="clearCache()" text="Clear Local Cache"></kendo-toolbar-button>
              </kendo-toolbar>
              <div style="display: flex; justify-content: center; align-items: center;"
                *ngIf="itemOwner && (currentAction === 'Edit')">
                <button kendoButton themeColor="warning" size="medium" rounded="full" class="m-1"
                  [disabled]="loading['rmItem']" title="Archive {{createMode === 'bb' ? 'Building Block': 'Recipe'}}"
                  *ngIf="!loadedItem.isArchived" (click)="delete()">
                  <i class="intelicon-arrow-forward-right"></i><i class="intelicon-archive-solid"></i>
                </button>
                <button kendoButton themeColor="warning" size="medium" rounded="full" class="m-1"
                  [disabled]="loading['rmItem']" title="Unarchive {{createMode === 'bb' ? 'Building Block': 'Recipe'}}"
                  *ngIf="loadedItem.isArchived && itemOwner"
                  (click)="loadedItem.isArchived = false; update(loadedItem['isDraft'])">
                  <i class="intelicon-arrow-back-left"></i><i class="intelicon-archive-solid"></i>
                </button>
              </div>
            </div>

          </div>
        </div>
        <!-- <div class="row" *ngIf="createMode === 'bb' && loadedItem && (currentAction === 'Edit')"> -->
        <div class="row" *ngIf="createMode === 'bb' && loadedItem && (currentAction === 'Edit') && this.readOnly === false">
          <div class="col">
            <div class="btn-group" style="float:right">
              <input type="checkbox" #notification [(ngModel)]="updateRecipes" kendoCheckBox />
              <kendo-label class="k-checkbox-label" [for]="notification"
                text="Update all associated recipes that *you* own.">
              </kendo-label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
<!--   <div class="row">
    <span style="display: inline-block">Jump to Section</span>
  </div> -->
  <div class="row">
    <kendo-buttongroup selection="multiple">
      <button class="col m-2" *ngFor="let key of formKeys" kendoButton rounded="full" fillmode="solid"
        [toggleable]="true" [selected]="formSteps[key].selected" (selectedChange)="setCurrentStep($event, key)">
        {{formSteps[key].label}}
        <kendo-badge *ngIf="!formSteps[key].isValid" size="small" [rounded]="'full'" [align]="badgeAlign" class="badge-z-index"
          [cutoutBorder]="true" themeColor="error">
          <kendo-icon name="error" class="k-badge-icon"></kendo-icon>
        </kendo-badge>
        <kendo-badge *ngIf="formSteps[key].isValid" size="small" [rounded]="'full'" [align]="badgeAlign" class="badge-z-index"
          [cutoutBorder]="true" themeColor="success">
          <kendo-icon name="check" class="k-badge-icon"></kendo-icon>
        </kendo-badge>
      </button>
    </kendo-buttongroup>
  </div>
  <div class="row">
    <button class="col" size="small" fillMode="link" kendoButton iconClass="fa fa-expand fa-fw"
      (click)="expandAll()">Expand All</button>
    <button class="col" size="small" fillMode="link" kendoButton iconClass="fa fa-compress fa-fw"
      (click)="collapseAll()">Collapse All</button>
  </div>
</div>
<!-- <div class="m-1" *ngIf="loading['rmItem']" style="text-align:center">
  <kendo-loader size="large"> </kendo-loader>
</div> -->
<form *ngIf="!loading['rmItem']" [formGroup]="submitRequestForm" class="k-form k-form-horizontal" style="height: 100%;">
  <div class="k-form k-form-horizontal">
    <div class="panelbar-wrapper">
      <kendo-panelbar [selectable]="true" (select)="onPanelSelect($event)" (collapse)="onPanelCollapse($event)" (expand)="onPanelExpand($event)">
        <kendo-panelbar-item *ngIf="(currentAction === 'Edit')" class="formSectionTitle" title="Existing Info"
          [selected]="true" [expanded]="true">
          <ng-template kendoPanelBarContent>
            <div class="row">
              <div *ngIf="(createMode === 'bb')" class="col">
                <kendo-label text="BB Number:&nbsp;" class="center-all">
                  <kendo-textbox [readonly]="true" [disabled]="true" [style.width]="'80%'"
                    [value]="loadedItem.buildingBlockNumber" name="buildingBlockNumber">
                  </kendo-textbox>
                </kendo-label>
              </div>
              <div *ngIf="(createMode === 'recipe')" class="col">
                <kendo-label text="Recipe Number:&nbsp;" class="center-all">
                  <kendo-textbox [readonly]="true" [disabled]="true" [style.width]="'80%'"
                    [value]="loadedItem.recipeNumber" name="recipeNumber">
                  </kendo-textbox>
                </kendo-label>
              </div>
              <div class="col">
                <kendo-label text="Revision:&nbsp;" class="center-all">
                  <kendo-textbox [readonly]="true" [disabled]="true" [style.width]="'80%'" [value]="loadedItem.revision"
                    name="revision"></kendo-textbox>
                </kendo-label>
              </div>
              <div class="col">
                <a *ngIf="(createMode === 'recipe') && loadedItem.parentRecipe && (loadedItem.parentRecipe.revision > -1) "
                  target="_new"
                  [href]="'#/recipe/edit/' + loadedItem.parentRecipe.id + '?revision=' + loadedItem.parentRecipe.revision">Parent</a>
                <span
                  *ngIf="(createMode === 'recipe') && loadedItem.parentRecipe && (loadedItem.parentRecipe.revision === -1) ">Parent:
                  None</span>
                <a *ngIf="(createMode === 'bb') && loadedItem.parentBuildingBlock && (loadedItem.parentBuildingBlock.revision > -1) "
                  target="_new"
                  [href]="'#/bb/edit/' + loadedItem.parentBuildingBlock.id + '?revision=' + loadedItem.parentBuildingBlock.revision">Parent</a>
                <span
                  *ngIf="(createMode === 'bb') && (!loadedItem.parentBuildingBlock || (loadedItem.parentBuildingBlock && (loadedItem.parentBuildingBlock.revision === -1)))">Parent:
                  None</span>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <kendo-label text="Created By:&nbsp;" class="center-all">
                  <kendo-textbox *ngIf="loadedItem.createdBy" [readonly]="true" [disabled]="true" [style.width]="'80%'"
                    [value]="loadedItem.createdBy.name" name="createdBy"></kendo-textbox>
                </kendo-label>
              </div>
              <div class="col">
                <kendo-label text="Last Updated By:&nbsp;" class="center-all">
                  <kendo-textbox *ngIf="loadedItem.updatedBy" [readonly]="true" [disabled]="true" [style.width]="'80%'"
                    [value]="loadedItem.updatedBy.name" name="updatedBy"></kendo-textbox>
                </kendo-label>
              </div>
            </div>
          </ng-template>
        </kendo-panelbar-item>

        <kendo-panelbar-item *ngIf="formSteps[ 'owners']" class="formSectionTitle"
          [expanded]="formSteps[ 'owners'].selected" formGroupName="owners" [selected]="formSteps[ 'owners'].selected"
          title="owners">
          <ng-template kendoPanelBarItemTitle>Owners
            <div *ngIf="formSteps['owners'].isValid" class="bg-success"
              style="width:100%; height: 5px; position: absolute; bottom: 0; left: 0"></div>
            <div *ngIf="!formSteps['owners'].isValid" class="bg-danger"
              style="width:100%; height: 5px; position: absolute; bottom: 0; left: 0"></div>
          </ng-template>
          <ng-template kendoPanelBarContent>
            <div class="pane-content row m-3">
              <kendo-formfield class="col-6 fieldFormat" orientation="horizontal">
                <kendo-label [for]="forOwnerName" text="Owner:"> </kendo-label>
                <kendo-dropdownlist [data]="ownerData" formControlName="owner" [textField]="'displayName'" #forOwnerName
                  [valueField]="'id'" [loading]="loading['owner']" (blur)="onBlur('owners','owner')">
                </kendo-dropdownlist>
                <kendo-formerror *ngIf="!selectedOwner?.displayName">Error: Employee Name is required
                </kendo-formerror>
              </kendo-formfield>
              <kendo-formfield class="col-6 fieldFormat" orientation="horizontal">
                <kendo-label [for]="forCoOwnerName" text="Co-Owner(s):">&nbsp;</kendo-label>
                <kendo-multiselect class="form-control" #forCoOwnerName [data]="coownerData" formControlName="coOwner"
                  [textField]="'displayName'" [valueField]="'id'"
                  (blur)="onBlur('owners', 'coOwner')" [itemDisabled]="coOwnerDisabled" [loading]="loading['coOwner']"
                  (valueChange)="coOwnerChanged()" (removeTag)="coOwnerChanged()"></kendo-multiselect>
                <kendo-formerror *ngIf="coOwners.length === 0">Error: At lease one co-owner is required
                </kendo-formerror>
                <kendo-formhint *ngIf="coOwners.length > 0">Info: Co-Owners limited to three
                </kendo-formhint>
              </kendo-formfield>
            </div>
          </ng-template>
        </kendo-panelbar-item>

        <!--Lab Manager Data -->
        <kendo-panelbar-item *ngIf="formSteps[ 'labManager']" class="formSectionTitle"
        [expanded]="formSteps[ 'labManager'].selected" formGroupName="labManager"
        [selected]="formSteps[ 'labManager'].selected" title="labManager">

          <ng-template kendoPanelBarItemTitle>
            Lab Manager
            <div *ngIf="true" class="bg-success"
              style="width:100%; height: 5px; position: absolute; bottom: 0; left: 0"></div>
              <div *ngIf="false" class="bg-danger"
              style="width:100%; height: 5px; position: absolute; bottom: 0; left: 0"></div>
          </ng-template>

          <ng-template kendoPanelBarContent>
            <div class="pane-content row m-3">
              <h6><b>Note:</b> The Lab Manager fields are mandatory to fill except Focus and Description fields to complete the consumption case in workq page.</h6>
              <br>
              <h6><b>(R) - Read Only</b> (Values will not be updated in Lab Manager)</h6><br>
              <h6><b>(R/W) - Read & Write</b> (Values will be updated in Lab Manager) </h6><br>


              <kendo-formfield class="col-2 fieldFormat" orientation="horizontal">
                <ul>
                  <li>Site (R)</li>
                  <li>Name (R)</li>
                  <li>Lab (R)</li>
                  <li>Group (R)</li>
                  <li>Board Sn (R)</li>
                  <li>Location (R)</li>

                </ul>
              </kendo-formfield>

              <kendo-formfield class="col-2 fieldFormat" orientation="horizontal">
                <ul>
                  <li>Project (R/W)</li>
                  <li>Owner (R/W)</li>
                  <li>Activity (R/W)</li>
                  <li>Active State (R/W)</li>
                  <li>Station VT (R/W)</li>
                  <li>Focus (R/W)</li>
                  <li>Description (R/W)</li>

                </ul>
              </kendo-formfield>
            </div>
          </ng-template>

        </kendo-panelbar-item>
        <kendo-panelbar-item *ngIf="formSteps[ 'basicFeatures']" class="formSectionTitle"
          [expanded]="formSteps[ 'basicFeatures'].selected" formGroupName="basicFeatures"
          [selected]="formSteps[ 'basicFeatures'].selected" title="basicFeatures">
          <ng-template kendoPanelBarItemTitle>
            Basic Features
            <div *ngIf="formSteps['basicFeatures'].isValid" class="bg-success"
              style="width:100%; height: 5px; position: absolute; bottom: 0; left: 0"></div>
            <div *ngIf="!formSteps['basicFeatures'].isValid" class="bg-danger"
              style="width:100%; height: 5px; position: absolute; bottom: 0; left: 0"></div>

          </ng-template>
          <ng-template kendoPanelBarContent>
            <div class="m-3">
              <div class="row">
                <kendo-formfield class="col-12" orientation="horizontal" showHints="initial">
                  <!-- <label class="k-label">Ramp Up Category: </label> -->
                  <label class="k-label">Ramp Up/Ramp Down Category: </label>
                  <ul class="k-radio-list k-list-horizontal">
                    <li class="k-radio-item">
                      <input type="radio" #poReadiness value="Power-On Readiness" formControlName="rampUp"
                        kendoRadioButton (blur)="onBlur('basicFeatures', 'rampUp')" required />
                      <kendo-label class="k-radio-label" [for]="poReadiness" text="Power-On Readiness"></kendo-label>
                    </li>
                    <li class="k-radio-item">
                      <input type="radio" #execution value="Execution" formControlName="rampUp" kendoRadioButton
                        (blur)="onBlur('basicFeatures', 'rampUp')" />
                      <kendo-label class="k-radio-label" [for]="execution" text="Execution"></kendo-label>
                    </li>
                    <li class="k-radio-item">
                      <input type="radio" #rampDown value="Ramp Down" formControlName="rampUp" kendoRadioButton
                        (blur)="onBlur('basicFeatures', 'rampUp')" />
                      <kendo-label class="k-radio-label" [for]="rampDown" text="Ramp Down"></kendo-label>
                    </li>
                  </ul>
                  <!-- <kendo-formhint>Choose one Ramp Up Category</kendo-formhint> -->
                  <kendo-formhint>Choose one Ramp Up/Ramp Down Category</kendo-formhint>
                  <kendo-formerror>Error: One category is required</kendo-formerror>
                </kendo-formfield>
                <kendo-formfield class="col-6 fieldFormat" orientation="horizontal">
                  <kendo-label text="Site(s)"></kendo-label>
                  <kendo-multiselect [data]="rmLabList" formControlName="labs" [allowCustom]="false"
                    [valueField]="'name'" (blur)="onBlur('basicFeatures', 'labs')" [textField]="'name'"
                    [loading]="loading['rmLabList']">
                  </kendo-multiselect>
                  <kendo-formhint>Choose one or more sites</kendo-formhint>
                  <kendo-formerror>Error: At least one site is required</kendo-formerror>
                </kendo-formfield>
                <kendo-formfield class="col" orientation="horizontal">
                  <kendo-label text="Validation Team(s)"></kendo-label>
                  <kendo-multiselect [data]="rmValidationTeams" formControlName="validationTeams" [allowCustom]="false"
                    [itemDisabled]="listItemDisabled" (blur)="onBlur('basicFeatures','validationTeams')"
                    [textField]="'name'" [valueField]="'name'" [loading]="loading['rmValidationTeams']">
                  </kendo-multiselect>
                  <kendo-formhint>Choose one or more Validation Teams</kendo-formhint>
                  <kendo-formerror>Error: At least one team is required</kendo-formerror>
                </kendo-formfield>
                <kendo-formfield class="col-6" orientation="horizontal">
                  <kendo-label text="Board Type(s)"></kendo-label>

                  <div class="btn-group" style="width: 100%;">
                    <kendo-multiselect [data]="rmBoardTypes" formControlName="boardTypes" [allowCustom]="false"
                      [itemDisabled]="listItemDisabled" [textField]="'name'" [valueField]="'name'" style="margin-right: 10px"
                      [loading]="loading['rmBoardTypes']" (blur)="onBlur('basicFeatures','boardTypes')">
                    </kendo-multiselect>
                    <button kendoButton *ngIf="!boardTypeOpened" (click)="boardTypeOpen()" icon="search"
                      title="Search Speed" [size]="'small'"></button>
                  </div>
                  <kendo-formhint>Choose one or more board types</kendo-formhint>
                  <kendo-formerror>Error: At least one type is required</kendo-formerror>
                </kendo-formfield>
                <kendo-formfield class="col-6" *ngIf="createMode === 'bb'" orientation="horizontal">
                  <kendo-label text="Module"></kendo-label>
                  <kendo-dropdownlist [data]="rmModules" formControlName="module" [itemDisabled]="listItemDisabled"
                    [loading]="loading['rmModules']" [textField]="'name'" [valueField]="'name'"
                    (blur)="onBlur('basicFeatures','module')">
                  </kendo-dropdownlist>
                  <kendo-formhint>Choose one module</kendo-formhint>
                  <kendo-formerror>Error: One module is required</kendo-formerror>
                </kendo-formfield>
                <kendo-formfield class="col-6" orientation="horizontal">
                  <kendo-label text="Silicon Program"></kendo-label>
                  <div class="btn-group" style="width: 100%;">
                    <kendo-dropdownlist [data]="rmSiliconPrograms" formControlName="siliconProgram"
                      [loading]="loading['rmSiliconPrograms']" (selectionChange)="siliconProgramChanged($event)"
                      (blur)="onBlur('basicFeatures','siliconProgram')" style="margin-right: 10px">
                    </kendo-dropdownlist>

                    <!-- <kendo-multiselect [data]="rmSiliconPrograms" formControlName="siliconProgram"
                    [loading]="loading['rmSiliconPrograms']" (valueChange)="siliconProgramChanged($event)"
                    (blur)="onBlur('basicFeatures','siliconProgram')" style="margin-right: 10px">
                    </kendo-multiselect> -->

                    <button kendoButton *ngIf="!programsOpened" (click)="programsOpen()" icon="search"
                      title="Search Speed" [size]="'small'"></button>
                  </div>
                  <kendo-formhint>Choose one program</kendo-formhint>
                  <kendo-formerror>Error: One program is required</kendo-formerror>
                </kendo-formfield>
                <kendo-formfield class="col-6" orientation="horizontal">
                  <kendo-label text="Product(s)"></kendo-label>
                  <div class="btn-group" style="width: 100%;">
                    <kendo-multiselect [data]="rmProducts" formControlName="products" [itemDisabled]="listItemDisabled"
                      [loading]="loading['rmProducts']" (blur)="onBlur('basicFeatures','products')" [textField]="'name'"
                      [valueField]="'name'">
                    </kendo-multiselect>
                    <!--                   <button kendoButton *ngIf="!productOpened" (click)="productOpen()" icon="search"
                    title="Search Speed" [size]="'small'"></button> -->
                  </div>
                  <kendo-formhint>Choose one or more product</kendo-formhint>
                  <kendo-formerror>Error: At least one product is required</kendo-formerror>
                </kendo-formfield>
                <kendo-formfield class="col-12" *ngIf="createMode === 'bb'" orientation="horizontal">
                  <kendo-label text="Name"></kendo-label>
                  <kendo-textbox formControlName="buildingBlockName" placeholder="Building Block Name"
                    (blur)="onBlur('basicFeatures','buildingBlockName')"></kendo-textbox>
                  <kendo-formerror>Error: Name is required</kendo-formerror>
                </kendo-formfield>
                <kendo-formfield class="col-12" *ngIf="createMode === 'recipe'" orientation="horizontal">
                  <kendo-label text="Name"></kendo-label>
                  <kendo-textbox formControlName="recipeName" placeholder="Recipe Name" [clearButton]="true"
                    (blur)="onBlur('basicFeatures','recipeName')"></kendo-textbox><br />
                  <span style="font-size: small">Free Text Mode: <input [checked]="freeTextModeTempValue" #freeTextMode
                      (change)="freeTextModeChanged($event)" type="checkbox" kendoCheckBox /></span>
                  <kendo-formerror>Error: Name is required</kendo-formerror>
                </kendo-formfield>
              </div>
              <div class="row">
                <div class="col">
                  <div class="row">
                    <kendo-formfield *ngIf="createMode === 'bb'" class="col" orientation="horizontal">
                      <kendo-label text="Building Block Type"></kendo-label>
                      <kendo-dropdownlist [data]="rmTypes" formControlName="type" [itemDisabled]="listItemDisabled"
                        [loading]="loading['rmTypes']" (blur)="onBlur('basicFeatures','type')" [textField]="'name'"
                        [valueField]="'name'">
                      </kendo-dropdownlist>
                      <kendo-formhint>Chose one Building Block Type</kendo-formhint>
                      <kendo-formerror>Error: One Type is required</kendo-formerror>
                    </kendo-formfield>
                  </div>
                  <div class="row">
                    <kendo-formfield class="col" orientation="horizontal">
                      <kendo-label [for]="forDescription" text="Description"> </kendo-label>
                      <kendo-textarea #forDescription formControlName="description" style="width: 100%;"
                        resizable="vertical" (blur)="onBlur('basicFeatures','description')"></kendo-textarea>
                    </kendo-formfield>
                  </div>
                </div>
                <div class="col m-3" style="border: 1px solid lightgray; border-radius: 5px;">
                  <div class="input-group input-custom-rounded" style="height: 40px">
                    <kendo-label class="input-group-prepend" style="position:relative; top:5px" [for]="forEmailDistName"
                      text="Email Distribution:">&nbsp;</kendo-label>
                    <kendo-multicolumncombobox class="form-control" #forEmailDistName [data]="emailDistributionData"
                      [textField]="'displayName'" [valueField]="'id'" (valueChange)="onEmailDistValueChange($event)"
                      (filterChange)="graphEmailDistribution($event)" [filterable]="true"
                      [placeholder]="'Employee Last Name, First or Email or WWID...'"
                      [loading]="loading['emailDistribution']">
                      <kendo-combobox-column [field]="'surname'" [title]="'Last Name'" [width]="200">
                      </kendo-combobox-column>
                      <kendo-combobox-column [field]="'givenName'" [title]="'First Name'" [width]="200">
                      </kendo-combobox-column>
                      <kendo-combobox-column [field]="'jobTitle'" [title]="'Employee WWID'" [width]="200">
                      </kendo-combobox-column>
                      <kendo-combobox-column [field]="'mail'" [title]="'Employee Email'" [width]="400">
                      </kendo-combobox-column>
                    </kendo-multicolumncombobox>
                    <div class="input-group-append">
                      <!-- <button class="button-custom-rounded mr-1" style="height: 100%" [disabled]="!selectedEmailDist"
                      kendoButton themeColor="primary" rounded="none" icon="plus"
                      (click)="addEmailDist(); forEmailDistName.reset()"></button> -->
                      <button class="button-custom-rounded mr-1" style="height: 100%" [disabled]="!selectedEmailDist"
                        kendoButton themeColor="primary" rounded="none"
                        (click)="addEmailDist(); forEmailDistName.reset()">Add</button>
                    </div>
                  </div>
                  <div class="row m-1">
                    <kendo-chip class="col-6" *ngFor="let email of loadedItem['emailDistribution']" [label]="email"
                      [removable]="true" (remove)="onRemoveEmailDist($event)"
                      (onblur)="onBlur('basicFeatures','emailDistribution')">
                    </kendo-chip>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </kendo-panelbar-item>




        <!-- Building Block - Block Manager [START] -->
        <kendo-panelbar-item class="formSectionTitle" [expanded]="formSteps[ 'blockManager'].selected"
          formGroupName="blockManager" [attr.id]="'blockManager'"
          *ngIf="createMode === 'bb' && formSteps[ 'blockManager']" [selected]="formSteps[ 'blockManager'].selected"
          title="blockManager">
          <ng-template kendoPanelBarItemTitle>
            Block Manager
            <div *ngIf="formSteps['blockManager'].isValid" class="bg-success"
              style="width:100%; height: 5px; position: absolute; bottom: 0; left: 0"></div>
            <div *ngIf="!formSteps['blockManager'].isValid" class="bg-danger"
              style="width:100%; height: 5px; position: absolute; bottom: 0; left: 0"></div>
          </ng-template>
          <ng-template kendoPanelBarContent>
            <div class="row m-3"
              style="z-index:10000; border: 1px solid darkgray; background-color: rgba(255, 255, 255, 0.8);">
              <div *ngIf="!readOnly" class="col-auto">
                <kendo-splitbutton class="m-1" [disabled]="uploadMode || isPdfLoading" text="Link Existing Document"
                  [data]="fileList" (itemClick)="getFileFromControl($event)">
                </kendo-splitbutton>
                <button class="m-1" kendoButton themeColor="primary" fillMode="outline" [togglable]="true"
                  (click)="uploadMode = !uploadMode; selectedDoc = null">Upload New Document
                </button>
                <button class="m-1" kendoButton *ngIf="selectedFile && selectedDocxFile" (click)="editDocument()">
                  View Current Document
                </button>
                <button class="m-1" kendoButton *ngIf="selectedFile" (click)="cancelDownload()">
                  Clear Current Document
                </button>
              </div>

              <div class="col-auto" *ngIf="uploadMode && !isPdfLoading">
                <!-- <kendo-upload
                  [saveUrl]="uploadSaveUrl"
                  [multiple]="false"
                  [restrictions]="bbRestrictions"
                  (upload)="docDupCheck($event)"
                  (success)="uploadComplete($event); uploadMode = false;"
                  >
                </kendo-upload>
                {{bbRestrictions.allowedExtensions ? bbRestrictions.allowedExtensions.join(', ') : ''}} Only. -->

                <!-- Workaround while pending Kendo Support Ticket -->
                <form #uploadForm="ngForm">
                  <div class="row" style="margin-top: 4px;">
                    <div class="col">
                      <div class="k-upload">
                        <input type="file" (change)="onDocSelected($event)" id="docInput"
                          class="k-upload-input form-control"
                          accept=".pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                          [(ngModel)]="selectedDoc" name="selectedDoc"
                          style="width: 400px;"
                        />
                        <div *ngIf="fileTypeError" class="text-danger mt-2">
                          Please select a valid file (PDF or DOCX).
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <button type="button"
                        (click)="uploadDoc()"
                        class="k-button k-upload-button btn btn-primary"
                        [disabled]="!selectedDoc || fileTypeError"
                      >Upload File</button>
                    </div>
                  </div>
                </form>
                {{bbRestrictions.allowedExtensions ? bbRestrictions.allowedExtensions.join(', ') : ''}} Only.

              </div>
            </div>
            <kendo-textbox [style.width.px]="350" style="visibility:hidden" [readonly]="true" formControlName="content"
              fillMode="none" class="custom-fill-mode"></kendo-textbox>
            <div class="row" *ngIf="selectedFile">
              <div class="col m-3 text-black center-all" style="background-color: var(--bs-info);">
                <strong style="display:inline-block">
                  <h3>{{ selectedFile }}&nbsp;</h3>
                </strong>
                <button style="display:inline-block" *ngIf="isPdfLoading" rounded="full" size="small"
                  themeColor="warning" kendoButton (click)="cancelDownload()">
                  <kendo-loader type="converging-spinner" size="medium"> </kendo-loader> Cancel
                </button>
              </div>
            </div>
            <div class="row m-3">
              <pdf-viewer
                *ngIf="base64"
                style="width: 100%; height: 600px"
                [src]="base64"
                [render-text]="true"
                [original-size]="true"
                [zoom]="1.2"
                (after-load-complete)="isPdfLoading=false">
              </pdf-viewer>
            </div>
          </ng-template>
        </kendo-panelbar-item>
        <!-- Building Block - Block Manager [END] -->

        <!-- Building Block - Checklist [START] -->
        <kendo-panelbar-item title="CheckList" class="formSectionTitle"
          *ngIf="createMode === 'bb' && formSteps['CheckList']" [expanded]="formSteps['CheckList'].selected"
          [selected]="formSteps['CheckList'].selected" formGroupName="CheckList">
          <ng-template kendoPanelBarItemTitle>
            Checklist
            <div *ngIf="formSteps['CheckList'].isValid" class="bg-success"
              style="width:100%; height: 5px; position: absolute; bottom: 0; left: 0"></div>
            <div *ngIf="!formSteps['CheckList'].isValid" class="bg-danger"
              style="width:100%; height: 5px; position: absolute; bottom: 0; left: 0"></div>
          </ng-template>

          <ng-template kendoPanelBarContent>
            <!-- formArray section that loads slowly when loading csv entries -->
            <ng-container formArrayName="checkList">
              <!-- <div class="input-group-append">
              </div> -->
              <kendo-grid [data]="loadedItem.checkList" (edit)="editCheckListItem($event)" [resizable]="true" [selectable]="{mode: 'single'}"
                (add)="addNewCheckListItem($event)" (cancel)="cancelCheckListItem($event)" [rowClass]="rowCallback"
                (save)="saveCheckListItem($event)" (remove)="removeCheckListItem($event)" [height]="410" (selectionChange)="checkListSelected($event)"
                [loading]="checklistGridLoading">
                    <!-- WH - checklistGridLoading not working as expected, future enhancement -->
                    <ng-template kendoGridLoadingTemplate>
                      <div class="k-loading-color"></div>
                      <div class="loading-container">
                        <kendo-loader type="converging-spinner" size="large"></kendo-loader>
                      </div>
                    </ng-template>

                <ng-template kendoGridToolbarTemplate let-formGroup="formGroup">
                    Insert: <kendo-dropdownlist [data]="listItems" #addWhere textField="text" [itemDisabled]="checkListItemDisabled"
                    valueField="value"  (selectionChange)="addWhereChanged()" [value]="this.listItems[3]">
                    </kendo-dropdownlist>
                  <button *ngIf="!this.checkListEditMode[-1] && !inEdit" kendoGridAddCommand>Add new</button>

                  <button themeColor="primary" fillMode="outline" kendoButton [togglable]="true"
                    (click)="showUploadCSV = !showUploadCSV">Upload RF CSV</button>
                  <kendo-upload *ngIf="showUploadCSV" [saveUrl]="uploadCSVUrl" (success)="uploadCSVComplete($event)"
                    [multiple]="false" (select)="csvFileSelected($event)" [restrictions]="checklistRestrictions">
                  </kendo-upload>
                  <button themeColor="warning" kendoButton (click)="clearCheckList()">Clear</button>
                  <!-- Lindy adding new button for export excel -->
                  <button *ngIf="this.createMode === 'bb' &&  (currentAction === 'Edit' || 'copy') && loadedItem.checkList.length !='0'" themeColor="secondary" fillMode="solid" kendoButton (click)="exportExcel()">Export to CSV</button>
                </ng-template>

                <!-- Checklist Grid Column [START] -->
                <kendo-grid-command-column title="Order" [width]="100">
                  <ng-template kendoGridCellTemplate let-isNew="isNew" let-rowIndex="rowIndex">
                    <kendo-buttongroup *ngIf="!isNew">
                      <button kendoButton (click)="move(rowIndex, -1)" [icon]="'sort-asc-sm'"
                        [disabled]="rowIndex === 0"></button>
                      <button kendoButton (click)="move(rowIndex, +1)" [icon]="'sort-desc-sm'"
                        [disabled]="rowIndex === loadedItem.checkList.length - 1"></button>
                    </kendo-buttongroup>
                  </ng-template>
                </kendo-grid-command-column>

                <kendo-grid-column field="isRequired" title="Required?" [width]="75" editor="boolean"
                  [style]="{'text-align': 'center'}">
                  <ng-template kendoGridEditTemplate let-formGroup="formGroup" >
                    <input *ngIf="formGroup.get('type').value !== 'Header'" type="checkbox" #notification kendoCheckBox [formControl]="$any(formGroup.get('isRequired'))" />
                  </ng-template>
                  <ng-template kendoGridCellTemplate  let-dataItem>
                    <div *ngIf="dataItem['type'] !== 'Header'">
                      <span *ngIf="dataItem['isRequired']" class="k-icon k-i-checkbox-checked k-color-info"></span>
                      <span *ngIf="!dataItem['isRequired']" class="k-icon k-i-checkbox k-color-info"></span>
                    </div>
                  </ng-template>
                </kendo-grid-column>

                <kendo-grid-column field="type" title="Item Type" [width]="200">
                  <ng-template kendoGridEditTemplate let-column="column" let-formGroup="formGroup" let-isNew="isNew">
                    <kendo-dropdownlist kendoGridFocusable [formControl]="$any(checkListFormGroup.get(column.field))"
                      [data]="checkListTypes"> </kendo-dropdownlist>
                    <div *ngIf="!formGroup.get('type').value" class="text-danger"><span
                        class="k-icon k-i-exclamation-circle"></span>
                      Checklist item type is required
                    </div>
                  </ng-template>
                </kendo-grid-column>

                <kendo-grid-column field="name" title="Name" [class]="{ nameColumn: true }">
                  <ng-template kendoGridEditTemplate let-column="column" let-formGroup="formGroup" let-isNew="isNew">
                    <kendo-textbox [formControl]="$any(checkListFormGroup.get(column.field))"></kendo-textbox>
                    <div *ngIf="!formGroup.get('name').value" class="text-danger"><span
                        class="k-icon k-i-exclamation-circle"></span>
                      Checklist item text is required (click on the right undo button to close this editor)</div>
                  </ng-template>
                </kendo-grid-column>

                <kendo-grid-command-column title="Edit" [width]="135">
                  <ng-template kendoGridCellTemplate let-isNew="isNew" let-rowIndex="rowIndex" let-formGroup="formGroup">
                    <!-- WH - disable row edit when checklist editor exist -->
                    <button [disabled]="this.checkListEditMode[-1] || inEdit"  kendoGridEditCommand [icon]="'edit'" title="Edit Item" [themeColor]="'primary'"></button>
                    <button [disabled]="this.checkListEditMode[-1] || inEdit" kendoGridRemoveCommand [icon]="'minus'" title="Delete Item" themeColor="warning"></button>
                    <button kendoGridSaveCommand [icon]="'check'"
                      [themeColor]="$any(checkListFormGroup.invalid)? 'error': 'success'"
                      [disabled]="$any(checkListFormGroup.invalid)" title="Save"></button>
                      <!-- <kendo-splitbutton kendoGridSaveCommand
                      [themeColor]="$any(checkListFormGroup.invalid)? 'error': 'success'"
                      [disabled]="$any(checkListFormGroup.invalid)" title="Save Changes"
                      [data]="checkListSaveOptions">Save</kendo-splitbutton>
                      (buttonClick)="saveCheckListItem($event, false)"    -->
                    <button kendoGridCancelCommand [icon]="'undo'" title="Undo Changes"></button>
                    <span [hidden]="!checkListEditMode[rowIndex]" style="font-size: x-small;">
                      <br/>
                      <!-- WH - 03Oct2023 - the next line input added the ngIf to prevent it run get() every row and slow down the rendering   -->
                      <input *ngIf="this.checkListEditMode[-1]" type="checkbox" [formControl]="$any(checkListFormGroup.get('saveMode'))" />
                       Save {{addWhereValue}} and New </span>
                  </ng-template>
                </kendo-grid-command-column>
                <!-- Checklist Grid Column [END] -->
              </kendo-grid>
            </ng-container>
          </ng-template>
        </kendo-panelbar-item>
        <!-- Building Block - Checklist [END] -->

        <!-- Recipe - Production Module and Bullding Block Selection [START] -->
        <kendo-panelbar-item title="orderBuildingBlocks" class="formSectionTitle"
          *ngIf="createMode === 'recipe' && formSteps[ 'orderBuildingBlocks']"
          [expanded]="formSteps['orderBuildingBlocks'].selected" [selected]="formSteps[ 'orderBuildingBlocks'].selected"
          formGroupName="orderBuildingBlocks">

          <ng-template kendoPanelBarItemTitle>
            Production Module and Building Block Selection
            <div *ngIf="formSteps['orderBuildingBlocks'].isValid" class="bg-success"
              style="width:100%; height: 5px; position: absolute; bottom: 0; left: 0"></div>
            <div *ngIf="!formSteps['orderBuildingBlocks'].isValid" class="bg-danger"
              style="width:100%; height: 5px; position: absolute; bottom: 0; left: 0"></div>
          </ng-template>

          <ng-template kendoPanelBarContent>
            <kendo-splitter *ngIf="rmActiveModules">

              <kendo-splitter-pane [collapsible]="true" [resizable]="true" size="300">
                <div class="row" *ngFor="let activeModule of rmActiveModules">
                  <div class="col-1">
                    <input type="checkbox" kendoCheckBox [checked]="activeModule.selected"
                      (change)="moduleCheckChange($event, activeModule)" />
                  </div>
                  <div class="col-auto">
                    <label class="k-checkbox-label">{{ activeModule.name
                      }}</label>
                  </div>
                </div>
              </kendo-splitter-pane>

              <kendo-splitter-pane [collapsible]="true" [resizable]="true">
                <kendo-splitter orientation="vertical" style="height: 600px" >
                  <kendo-splitter-pane [collapsible]="true" [resizable]="true">

                    <kendo-sortable #selectedRecipeModulesSortable [kendoSortableBinding]="selectedRecipeModules"
                      [itemStyle]="{ border: '1px solid goldenrod', opacity: '1', cursor: 'move' }"
                      [emptyItemStyle]="{ height: '30px', border: '2px dashed black' }"
                      [activeItemStyle]="{ border: '2px dashed black', opacity: '0.7' }"
                      (dragEnd)="updateRecipeModuleForm();onBBReOrder(true)">

                      <ng-template let-item="item">
                        <div class="row m-3" style="background-color: #00C7FD;">
                          <div class="col-1 m-1">
                            <kendo-icon name="move" size="medium" style="float:left" ></kendo-icon>
                          </div>
                          <div class="col" style="cursor:pointer !important">
                            <h5>{{item}}({{selectedRecipeBuildingBlocks[item] ? selectedRecipeBuildingBlocks[item].length :
                              '0'}}/{{preSelectedBuildingBlocks[item].length}})</h5>
                          </div>
                          <div class="col-1" style="cursor:pointer !important"
                          (click)="recipeModuleExpanded[item] = !recipeModuleExpanded[item]">
                            <kendo-icon name="arrow-chevron-up" style="float:right" size="medium"
                              *ngIf="recipeModuleExpanded[item]"></kendo-icon>
                            <kendo-icon name="arrow-chevron-down" style="float:right" size="medium"
                              *ngIf="!recipeModuleExpanded[item]"></kendo-icon>
                          </div>
                        </div>
                        <div class="row" #collapse="ngbCollapse" [(ngbCollapse)]="recipeModuleExpanded[item]">
                        <!-- <div class="row" #collapse="ngbCollapse" [(ngbCollapse)]="recipeModuleExpanded[item]"></div> -->
                          <!-- disable the filter and group until it is usable
                            <kendo-grid
                            [kendoGridBinding]="preSelectedBuildingBlocks[item]" [resizable]="true" [height]="410"
                            [sortable]="true"
                            (dataStateChange)="dataStateChange($event, item)"
                            [filterable]="'menu'"
                            kendoGridExpandGroupBy [groupsInitiallyExpanded]="initiallyExpanded"
                            [group]="group" [(expandedGroupKeys)]="expandedGroupKeys"
                            >
                           -->
                          <kendo-grid
                            [kendoGridBinding]="preSelectedBuildingBlocks[item]" [resizable]="true" [height]="510"
                            [sortable]="true" (sortChange)="recipeModuleGridSortChange($event, item)"
                            [filterable]="'menu'" (filterChange)="recipeModuleGridFilterChange($event, item)"
                          >

                            <kendo-grid-column title="Select" [width]="100">
                              <ng-template kendoGridCellTemplate let-rowIndex="rowIndex">
                                <input type="checkbox" [checked]="preSelectedBuildingBlocks[item][rowIndex].selected"
                                  (change)="bbCheckChange($event, item, rowIndex)" />
                              </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column field="isDraft" title="Draft" [sortable]="false">
                            </kendo-grid-column>
                            <kendo-grid-column field="ownerName" title="Owner">
                            </kendo-grid-column>
                            <kendo-grid-column field="isTemplate" title="Template" [sortable]="false">
                            </kendo-grid-column>
                            <kendo-grid-column field="buildingBlockNumber" title="Number">
                            </kendo-grid-column>
                            <kendo-grid-column field="revision" title="Rev" [sortable]="false">
                            </kendo-grid-column>
                            <kendo-grid-column field="module" title="Module">
                            </kendo-grid-column>
                            <kendo-grid-column field="type" title="Type">
                            </kendo-grid-column>
                            <kendo-grid-column field="buildingBlockName" title="Name">
                            </kendo-grid-column>
                            <kendo-grid-column field="labs" title="Site(s)">
                            </kendo-grid-column>
                            <kendo-grid-column field="products" title="Product(s)">
                            </kendo-grid-column>
                            <kendo-grid-column field="validationTeams" title="Validation Team(s)">
                            </kendo-grid-column>
                            <kendo-grid-column field="rampUp" title="Ramp">
                            </kendo-grid-column>
                            <kendo-grid-column field="content" title="Document" [sortable]="false">
                            </kendo-grid-column>
                          </kendo-grid>
                        </div>
                      </ng-template>
                    </kendo-sortable>
                  </kendo-splitter-pane>

                  <kendo-splitter-pane [collapsible]="true" [resizable]="true">
                    <div *ngIf="selectedRecipeModules">
                      <div class="container" *ngFor="let module of selectedRecipeModules">
                        <div class="row m-3" style="background-color: #FEC91B;"
                          (click)="expandedModulePanel[module] = !expandedModulePanel[module]">
                          <div class="col">
                            <h5>{{module}}({{selectedRecipeBuildingBlocks[module] ?
                              selectedRecipeBuildingBlocks[module].length: '0'}})</h5>
                          </div>
                          <div class="col-1">
                            <kendo-icon class="m-1" name="arrow-chevron-up" style="float:right"
                              *ngIf="expandedModulePanel[module]"></kendo-icon>
                            <kendo-icon class="m-1" name="arrow-chevron-down" style="float:right"
                              *ngIf="!expandedModulePanel[module]"></kendo-icon>
                          </div>
                        </div>
                        <div class="container m-3" #collapse="ngbCollapse" [(ngbCollapse)]="expandedModulePanel[module]">
                        <!-- <div class="container m-3" #collapse="ngbCollapse" [(ngbCollapse)]="!expandedModulePanel[module]">-->
                          <!--
                            <kendo-grid
                            [kendoGridBinding]="selectedRecipeBuildingBlocks[module]" [resizable]="true" [height]="410"
                            [filterable]="'menu'" [sortable]="true"
                            kendoGridExpandGroupBy
                            [groupsInitiallyExpanded]="initiallyExpanded"
                            [group]="group"
                            [(expandedGroupKeys)]="expandedGroupKeys"
                          >
                           -->
                          <kendo-grid
                            [kendoGridBinding]="selectedRecipeBuildingBlocks[module]" [resizable]="true" [height]="410"
                            [filterable]="false" [sortable]="false"
                          >
                            <kendo-grid-column title="ReOrder" [width]="100">
                              <ng-template kendoGridCellTemplate let-rowIndex="rowIndex">
                                <kendo-buttongroup>
                                  <button kendoButton (click)="moveSelectedRecipeBuildingBlocks(module, rowIndex, -1)"
                                    [icon]="'sort-asc-sm'" [disabled]="rowIndex === 0"></button>
                                  <button kendoButton (click)="moveSelectedRecipeBuildingBlocks(module, rowIndex, +1)"
                                    [icon]="'sort-desc-sm'"
                                    [disabled]="rowIndex === selectedRecipeBuildingBlocks[module].length - 1"></button>
                                </kendo-buttongroup>
                              </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column title="Link" [width]="100">
                              <ng-template kendoGridCellTemplate let-row>
                                <a target="_blank" style="cursor: pointer"
                                  [href]="'#/bb/edit/' + row.id + '?createdByWwid=' + row.createdByWwid"><span
                                    class="k-icon k-i-link-vertical"></span></a>
                              </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column field="isDraft" title="Draft">
                            </kendo-grid-column>
                            <kendo-grid-column field="isTemplate" title="Template">
                            </kendo-grid-column>
                            <kendo-grid-column field="module" title="Module">
                            </kendo-grid-column>
                            <kendo-grid-column field="buildingBlockName" title="Name">
                            </kendo-grid-column>
                            <kendo-grid-column field="type" title="Type">
                            </kendo-grid-column>
                            <kendo-grid-column field="buildingBlockNumber" title="BB Number">
                            </kendo-grid-column>
                          </kendo-grid>
                        </div>
                      </div>
                    </div>
                  </kendo-splitter-pane>
                </kendo-splitter>
              </kendo-splitter-pane>
            </kendo-splitter>
          </ng-template>

        </kendo-panelbar-item>
        <!-- Recipe - Production Module and Bullding Block Selection [END] -->

        <!-- Recipe - Checklist Preview [START] -->
        <kendo-panelbar-item *ngIf="checklistList.length > 0" title="checklistPreview" class="formSectionTitle">
          <ng-template kendoPanelBarItemTitle>
            Checklist Preview
            <div class="bg-primary" style="width:100%; height: 5px; position: absolute; bottom: 0; left: 0"></div>
          </ng-template>
          <ng-template *ngIf="checklistList.length > 0" kendoPanelBarContent>
            <ul>
              <li *ngFor="let module of checklistList; let i = index">
                <h4>
                  Module: {{ module.moduleName }}
                </h4>
                <ul>
                  <li *ngFor="let bb of module.bb; let i = index">
                    <h5>
                      {{ i + 1 }} - {{ bb.buildingBlockNumber}} - <b>{{ bb.buildingBlockName }}</b>
                    </h5>
                    <ul *ngIf="bb.checkList.length > 0">
                      <kendo-grid [data]="bb.checkList">
                          <kendo-grid-column [width]="60" field="type" title="Type"> </kendo-grid-column>
                          <kendo-grid-column [width]="250" field="name" title="Name"> </kendo-grid-column>
                          <kendo-grid-column [width]="40" field="isRequired" title="Required"> </kendo-grid-column>
                      </kendo-grid>
                      <!-- <li  *ngFor="let checkList of bb.checkList; let i = index">
                        {{ checkList.type }} - {{ checkList.name }} - {{ checkList.isRequired }}
                      </li> -->
                    </ul>
                    <ul *ngIf="bb.checkList.length === 0">
                      <li >
                        <h5>No checklist</h5>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </ng-template>
          <ng-template *ngIf="checklistList.length === 0">
            <h6>Please select building block to view the checklist.</h6>
          </ng-template>
        </kendo-panelbar-item>
        <!-- Recipe - Checklist Preview [END] -->

        <!-- Recipe - Document Preview [START] -->
        <kendo-panelbar-item title="documentPreview" class="formSectionTitle"
          *ngIf="createMode === 'recipe' && formSteps[ 'documentPreview']"
          [expanded]="formSteps['documentPreview'].selected" [selected]="formSteps[ 'documentPreview'].selected">
          <ng-template kendoPanelBarItemTitle>
            Document Preview
            <div *ngIf="formSteps['documentPreview'].isValid" class="bg-success"
              style="width:100%; height: 5px; position: absolute; bottom: 0; left: 0"></div>
            <div *ngIf="!formSteps['documentPreview'].isValid" class="bg-danger"
              style="width:100%; height: 5px; position: absolute; bottom: 0; left: 0"></div>
          </ng-template>
          <ng-template kendoPanelBarContent>
            <span *ngIf="!recipeDoc.url">No documents to load</span>
            <kendo-loader type="converging-spinner" *ngIf="isCombinedPdfLoading" size="medium"> </kendo-loader>
            <a *ngIf="recipeDoc.url" [href]="recipeDoc.url" target="_new">Open Pdf</a>
            <pdf-viewer
              *ngIf="recipeDoc"
              style="width: 100%; height: 600px"
              [src]="recipeDoc"
              [render-text]="true"
              [original-size]="true"
              [zoom]="1.2"
              (after-load-complete)="isCombinedPdfLoading=false">
            </pdf-viewer>
          </ng-template>
        </kendo-panelbar-item>
        <!-- Recipe - Document Preview [END] -->
      </kendo-panelbar>
    </div>
  </div>
</form>

<kendo-dialog title="Board Type Speed Search" *ngIf="boardTypeOpened" (close)="boardTypeClose()">
  <kendo-textbox #input="popupAnchor" popupAnchor (valueChange)="boardPBAIdTextBoxOnChange($event)"
    style="width: 180px; margin-right: 10px;" [size]="'small'" placeholder="Board PBA ID"></kendo-textbox>
  <kendo-popup [anchor]="input.element" *ngIf="boardPBAIdHasError"
    popupClass="k-widget k-tooltip k-tooltip-validation k-invalid-msg">
    <div style="width:150px"><span class="k-icon k-i-exclamation-circle"></span> {{ boardPBAIdErrorMessage }}</div>
  </kendo-popup>
  <button *ngIf="!this.loading['speedBoardTypes']" kendoButton (click)="searchBoardType()" icon="search" title="Search"
    [size]="'small'"></button>
  <kendo-loader type="converging-spinner" *ngIf="loading['speedBoardTypes']" size="small"> </kendo-loader>
  <hr />
  <span style="width:100%;text-align: center;">
    <h3>{{speedBoardTypeNameSelected}}</h3>
  </span>
  <!--   <kendo-textbox style="width: 100%; margin-right: 10px;" [size]="'small'" placeholder="Found Board Type"
    [value]="speedBoardTypeNameSelected" [disabled]="true"></kendo-textbox> -->
  <kendo-dialog-actions>
    <button kendoButton (click)="boardTypeClose()" [disabled]="!speedBoardTypeNameSelected" themeColor="primary">
      Add Board Type
    </button>
  </kendo-dialog-actions>
</kendo-dialog>

<kendo-dialog title="Program Speed Search" *ngIf="programsOpened" (close)="programsClose()">
  <div class="btn-group"><!-- [suggest]="true" -->
    <kendo-autocomplete #programsAutoComplete [data]="programNamesList" placeholder="Search for Program"
      (valueChange)="programsValueChange($event)"  [size]="'small'" style="width: 180px; margin-right: 10px;"
      [loading]="programsAutoCompleteIsLoading">
    </kendo-autocomplete>
    <button kendoButton (click)="searchPrograms()" icon="search" title="Search" [size]="'small'"></button>
  </div>
  <div>
    <kendo-combobox *ngIf="programsKeyword" placeholder="Select Product" #productComboBox [data]="productsList"
      textField="productName" valueField="productID" [size]="'small'"
      [(ngModel)]="speedProductSelected"></kendo-combobox>
  </div>
  <hr />
  <span style="width:100%;text-align: center;">
    <h3>{{speedProductSelected.siliconProgramName}}/{{speedProductSelected.productName}}</h3>
  </span>
  <kendo-dialog-actions>
    <button kendoButton (click)="programsAdd()" [disabled]="!speedProductSelected" themeColor="primary">
      Add Product
    </button>
  </kendo-dialog-actions>
</kendo-dialog>

<kendo-dialog title="Product Speed Search" *ngIf="productOpened" (close)="productClose()">
  <div class="btn-group">
    <kendo-autocomplete #programsAutoComplete [data]="productsList" placeholder="Search for Product"
      (valueChange)="productValueChange($event)" [suggest]="true" [size]="'small'"
      [loading]="productAutoCompleteIsLoading">
    </kendo-autocomplete>
  </div>

  <hr />
  <span style="width:100%;text-align: center;">
    <h3>{{speedProductSelected.siliconProgram}}/{{speedProductSelected.productName}}</h3>
  </span>
  <kendo-dialog-actions>
    <button kendoButton (click)="programsClose()" [disabled]="!speedProductSelected" themeColor="primary">
      Add Product
    </button>
  </kendo-dialog-actions>
</kendo-dialog>

<kendo-dialog *ngIf="alertDialogOpen" (close)="alertDialogClose()" [minWidth]="150" [width]="350" style="z-index: 99999;">
  <kendo-dialog-titlebar>
      <div>
          <i class="intelicon-passed-completed-outlined" aria-hidden="true"></i> Info: {{alertDialogTitle}}
      </div>
  </kendo-dialog-titlebar>
  <p style="margin: 30px; text-align: center;">
      <span [innerHTML]="alertDialogMessage"></span>
  </p>
  <kendo-dialog-actions>
      <button type="button" class="k-button k-primary" (click)="alertDialogAction()">OK</button>
  </kendo-dialog-actions>
</kendo-dialog>
