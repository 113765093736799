export const environment = {
  production: false,
  test: false,
  commitVersion: '20241010.1',
  urlBase: 'https://rm-dev.laas.intel.com/',
    appInsights: {
      instrumentationKey: '5b6f3e16-cccc-475c-bd9b-9ef96478e94c',
      connectionString: 'InstrumentationKey=5b6f3e16-cccc-475c-bd9b-9ef96478e94c;IngestionEndpoint=https://westus2-1.in.applicationinsights.azure.com/;LiveEndpoint=https://westus2.livediagnostics.monitor.azure.com/'
    },
  rmAccess:[
    "RM_Admins",
    "RM_L1_Technicians",
    "RM_L2_Technicians",
    "RM_Lab_Customers"
 ],
  services:{
    environmentPrefix: 'https://laas-aks-dev01.laas.icloud.intel.com/',
    FrontEndComposerUrl: 'FrontendComposer/',
    GraphUsersUrl: 'https://laas-aks-dev01.laas.icloud.intel.com/rmauthentication/',
    ImageMetadataUrl: 'https://graph.microsoft.com/v1.0/me/photo/',
    SearchUrl: 'rmrecipe/api',
    FilesUrl: 'https://laas-aks-dev01.laas.icloud.intel.com/rmfile/api/RMFileService',
    CreateUrl: 'rmcreate/api',
    HsdEsUrl: 'https://gts2-dev.apps1-pg-int.icloud.intel.com/#/newticket?',
    GtsSupportURL: 'https://hsdes-pre.intel.com/appstore/TMTTicketSystemPRENEXTDEMO/#/newticket?ticket_category=Tool%20Support&site=Penang&service_type=tool&site_lab_org=Penang&org_unit=iVE%20Labs&component=tool.Recipe%20Management%20&program=Non%20Program%20Specific',
    HsdEsWebUrl: 'https://hsdes-pre.intel.com/appstore/',
    ThirdPartyAppUrl: 'rmthirdpartytools/api',
    RMHelpUrl: 'https://wiki.ith.intel.com/display/RM/Release+Details',
    RMTicketsUrl: 'https://hsdes.intel.com/appstore/community/#/1204037988?queryId=15014193547'

  }
};